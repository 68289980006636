import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import { NAVBAR_PATHS } from '../../../../utils/constants/Paths'
import { SonixCard } from '../../../../components/SonixCard'
import { CONTENT } from '../../../../utils/mockData/homeContent'

export const ProductsSection = () => {
  return (
    <section
      id={NAVBAR_PATHS.products}
      className="row align-items-center justify-content-center p-5 min-vh-100 m-0 bg-secondary"
    >
      <p className="lead text-uppercase text-white text-center"> {CONTENT.products.breadcrumb} </p>

      <h2 className="lh-1 mb-3 text-white text-center">{CONTENT.products.page_title}</h2>

      <Swiper
        className="mt-3"
        slidesPerView={'auto'}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
          stopOnLastSlide: false
        }}
      >
        {
          CONTENT.products.infos.map(({ title, src, text, id }) => (
            <SwiperSlide key={id} className="text-center">
              <SonixCard
                cardImageName={src}
                cardTitle={title}
                cardText={text}
              />
            </SwiperSlide>
          ))
        }
      </Swiper>
    </section>
  )
}
