import { SonixNavbar } from "../../components/SonixNavbar"
import { HomeSection } from "./components/HomeSection"
import { AboutSection } from "./components/AboutSection"
import { ServicesSection } from "./components/ServicesSection"
import { ProductsSection } from "./components/ProductsSection"
import { BePartnerSection } from "./components/BePartnerSection"
import { ContactSection } from "./components/ContactSection"
import { HomeWave } from "./components/HomeWave"
import { ServicesWave } from "./components/ServicesWave"
import { ContactWave } from "./components/ContactWave"
import { ProductsWave } from "./components/ProductsWave"
import { WppButton } from "./components/WppButton"
import { SonixFooter } from "../../components/SonixFooter"
import { SonixBanner } from "../../components/SonixBanner"

export const Home = () => {
  return (
    <>
      <SonixNavbar />
      <HomeSection />
      <HomeWave />
      <AboutSection />
      <ServicesWave />
      <ServicesSection />
      <ProductsWave hasBg={true} />
      <ProductsSection />
      <ProductsWave needToRotate={true} />
      <BePartnerSection />
      <ContactWave />
      <ContactSection />
      <SonixFooter />
      <WppButton />
      <SonixBanner />
    </>
  )
}