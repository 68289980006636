import Button from 'react-bootstrap/Button'
import { NAVBAR_PATHS } from '../../../../utils/constants/Paths'
import { CONTENT } from '../../../../utils/mockData/homeContent'

export const HomeSection = () => {
  return (
    <section
      className="row align-items-start mx-0 p-5 background-banner"
    >
      <div className="col-lg-6 col-md-8 col-12 banner-text">
        <h1
          className="display-5 fw-bold lh-1 text-danger"
          dangerouslySetInnerHTML={{ __html: CONTENT.home.title }}
        >
        </h1>
        <div className="mt-4">
          <a href={`#${NAVBAR_PATHS.products}`}>
            <Button type="button" className="btn btn-primary btn-lg px-4 me-md-2">
              {CONTENT.home.button_label}
            </Button>
          </a>
        </div>
      </div>
    </section>
  )
}