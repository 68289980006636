import { NAVBAR_PATHS } from '../../../../utils/constants/Paths'
import { SonixCard } from '../../../../components/SonixCard'
import { CONTENT } from '../../../../utils/mockData/homeContent'

export const ServicesSection = () => {
  return (
    <section
      id={NAVBAR_PATHS.services}
      className="row align-items-center justify-content-center p-5 min-vh-100 m-0 bg-body-tertiary"
    >
      <p className="lead text-uppercase text-center"> {CONTENT.services.breadcrumb} </p>

      <h2 className="text-body-emphasis lh-1 mb-3 text-center">{CONTENT.services.page_title}</h2>
      <p className="lead text-center">{CONTENT.services.description}</p>

      <div className="d-flex mb-3 text-center gap-3 pb-3 justify-content-center align-items-center flex-wrap">
        {
          CONTENT.services.infos.map(({ title, src, id }) => (
            <SonixCard
              key={id}
              cardImageName={src}
              cardTitle={title}
              biggerHeight={true}
            />
          ))
        }
      </div>
    </section>
  )
}
