import { ASSETS_PATH } from "../constants/Paths";
import { CONTACTS } from "./contacts";
import { PRODUCTS } from "./products";
import { SERVICES } from "./services";

export const CONTENT = {
  navbar: {
    home: 'Home',
    about: 'Sobre',
    services: 'Serviços',
    products: 'Produtos',
    bePartner: 'Seja parceiro',
    blog: 'Fique atualizado',
    contact: 'Contato',
    call: 'Central de atendimento '
  },
  about: {
    breadcrumb: 'Sobre',
    page_title: 'Saiba mais sobre a Sonix',
    image: {
      src: `${ASSETS_PATH}sobre.jpg`,
      alt: 'Senhores tomando café da manhã'
    },
    title: 'Audição ao alcance de todos',
    paragraphs: [
      {
        id: 1,
        text: 'Com mais de 20 anos de experiência no mercado, nossa prioridade é gerar qualidade de vida aos nossos pacientes e oferecer o que há de melhor em tecnologia e inovação na indústria de aparelhos auditivos.'
      },
      {
        id: 2,
        text: 'Atendemos em todo Rio de Janeiro, online e em domicílios, e possuímos unidades em Jacarepaguá e Madureira - nossa meta é levar a audição ao alcance de todos.'
      }
    ],
    button_label: 'Saiba mais'
  },
  bePartner: {
    breadcrumb: 'Seja parceiro',
    page_title: 'Quero ser parceiro Sonix',
    title: 'Você é fonoaudiólogo? Faça parte da nossa história sendo um parceiro Sonix.',
    button_label: 'Quero saber mais'
  },
  contact: {
    breadcrumb: 'Contato',
    page_title: 'Fale conosco',
    infos: CONTACTS
  },
  home: {
    title: 'Os mais modernos <strong> aparelhos auditivos </strong> do mercado!',
    button_label: 'Confira os modelos disponíveis'
  },
  products: {
    breadcrumb: 'Produtos',
    page_title: 'Conheça nossos produtos',
    infos: PRODUCTS
  },
  services: {
    breadcrumb: 'Serviços',
    page_title: 'Conheça nossos serviços',
    description: 'Oferecemos serviços para solucionar seus problemas da forma mais eficiente.',
    infos: SERVICES
  },
  blog: {
    breadcrumb: 'Fique atualizado',
    page_title: 'Confira nossas últimas notícias'
  }
}