import { ASSETS_PATH } from "../constants/Paths";

export const SERVICES = [
  {
    id: 4,
    title: 'Avaliação e seleção do aparelho auditivo',
    src: `${ASSETS_PATH}avaliacao_selecao.jpeg`
  },
  {
    id: 1,
    title: 'Ajuste e regulagem',
    src: `${ASSETS_PATH}ajuste_e_regulagem.jpeg`
  },
  {
    id: 5,
    title: 'Revisão e assistência técnica',
    src: `${ASSETS_PATH}revisao_assistencia.jpeg`
  },
  {
    id: 2,
    title: 'Atendimento domiciliar',
    src: `${ASSETS_PATH}atendimento_domiciliar.jpeg`
  },
  {
    id: 3,
    title: 'Atendimento remoto',
    src: `${ASSETS_PATH}atendimento_remoto.jpeg`
  }
]