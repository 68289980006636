import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom';
import { ASSETS_PATH, NAVBAR_PATHS, ROUTE_PATHS } from '../../utils/constants/Paths';
import { scrollTop } from '../../utils/helpers/scroll';
import { CONTENT } from '../../utils/mockData/homeContent';
import { openPage } from '../../utils/helpers/window';
import { LINKS } from '../../utils/mockData/links';

export const SonixNavbar = ({ isBlog, isBlogPost }) => {
  const logoPath = `${ASSETS_PATH}logo.png`;

  return (
    <header>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand onClick={scrollTop}>
            <img src={logoPath} alt="Logo da Sonix aparelhos auditivos" width="150" height="58" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {isBlog && (
                <Link to={ROUTE_PATHS.home} className="nav-link">
                  {CONTENT.navbar.home}
                </Link>
              )}
              {
                !isBlog && (
                  <>
                    <Nav.Link href={`#${NAVBAR_PATHS.about}`} className="nav-link">
                      {CONTENT.navbar.about}
                    </Nav.Link>
                    <Nav.Link href={`#${NAVBAR_PATHS.services}`} className="nav-link">
                      {CONTENT.navbar.services}
                    </Nav.Link>
                    <Nav.Link href={`#${NAVBAR_PATHS.products}`} className="nav-link">
                      {CONTENT.navbar.products}
                    </Nav.Link>
                    <Nav.Link href={`#${NAVBAR_PATHS.partner}`} className="nav-link">
                      {CONTENT.navbar.bePartner}
                    </Nav.Link>
                    <Link to={ROUTE_PATHS.blog} className="nav-link">
                      {CONTENT.navbar.blog}
                    </Link>
                    <Nav.Link href={`#${NAVBAR_PATHS.contact}`} className="nav-link">
                      {CONTENT.navbar.contact}
                    </Nav.Link>
                  </>
                )
              }
            </Nav>

            <Button type="button" onClick={() => openPage(`${LINKS.wpp}/?text=Digite%20sua%20dúvida`)}>
              {CONTENT.navbar.call}
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};
