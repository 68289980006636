import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"

export const BlogCard = ({ image, brief, readingTime, path, title }) => {
  const navigate = useNavigate()
  const postFullContent = () => {
    navigate(path)
  }

  return (
    <div className="album shadow w-100" onClick={postFullContent}>
      <div className="card shadow-sm">
        <img className="rounded" src={image.src} alt={image.alt} />
        <div className="card-body">
          <p className="fw-bold fs-5">{title}</p>
          <p className="">{brief}</p>
          <div className="d-flex justify-content-between align-items-center">
            <div className="btn-group">
              <button type="button" className="btn btn-sm btn-outline-primary">Visualizar</button>
            </div>
            <small className="text-body-secondary">
              <FontAwesomeIcon icon={['fas', 'clock-four']} className="mx-2" />
              {readingTime}
            </small>
          </div>
        </div>
      </div>
    </div>
  )
}