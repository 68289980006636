import { SonixNavbar } from "../../components/SonixNavbar"
import { SonixFooter } from "../../components/SonixFooter"
import { CONTENT } from '../../utils/mockData/homeContent';
import { BLOG_CONTENT } from "../../utils/mockData/blogContent";
import { BlogCard } from "./components/BlogCard";

export const Blog = () => {
  return (
    <>
      <SonixNavbar isBlog={true} />
      <div className="py-5 d-flex text-center flex-column bg-dark">
        <p className="lead text-uppercase text-white"> {CONTENT.blog.breadcrumb} </p>
        <h2 className="mb-3 text-white">{CONTENT.blog.page_title}</h2>
      </div>
      <section className="d-flex flex-column align-items-center bg-body-tertiary p-3 gap-3">
        {
          BLOG_CONTENT.map(({ id, brief, image, path, readingTime, title }) => (
            <BlogCard
              key={id}
              brief={brief}
              image={image}
              path={path}
              readingTime={readingTime}
              title={title}
            />
          ))
        }
      </section>
      <SonixFooter />
    </>
  )
}