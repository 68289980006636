import { LINKS } from "./links";

export const CONTACTS = [
  {
    id: 1,
    title: 'Endereço',
    icon: 'location-pin',
    texts: [
      {
        id: 11,
        label: 'Unidade Jacarepaguá:',
        value: 'Estrada dos Três Rios 90 loja 222, Freguesia',
        link: 'https://www.google.com.br/maps/place/Sonix/@-22.9397235,-43.3451502,17z/data=!3m2!4b1!5s0x9bd83307cfafa3:0xcad69cfa295386c!4m6!3m5!1s0x9bd8313c5eb28d:0x9ffdcc355001166a!8m2!3d-22.9397285!4d-43.3425753!16s%2Fg%2F1v3_3xs7?entry=ttu'
      },
      {
        id: 12,
        label: 'Unidade Madureira:',
        value: 'Estrada do Portela, 99 - Loja 238',
        link: 'https://www.google.com.br/maps/place/Sonix/@-22.8728579,-43.341379,17z/data=!3m2!4b1!5s0x9962d825ce1bf9:0x42e878f4366b56ac!4m6!3m5!1s0x99635a3b1fae71:0x35412b18c416fb66!8m2!3d-22.8728629!4d-43.3388041!16s%2Fg%2F11swzkf8fy?entry=ttu'
      }
    ]
  },
  {
    id: 2,
    title: 'E-mail',
    icon: 'envelope',
    texts: [
      {
        id: 21,
        value: 'contato@sonix.com.br',
        link: 'mailto:contato@sonix.com.br'
      }
    ]
  },
  {
    id: 3,
    title: 'Telefone',
    icon: 'phone-alt',
    texts: [
      {
        id: 31,
        label: 'Unidade Jacarepaguá:',
        value: '(21) 2427-8817',
        link: 'tel:+552124278817'
      },
      {
        id: 32,
        label: 'Unidade Madureira:',
        value: '(21) 3251-8972',
        link: 'tel:+552132518972'
      },
      {
        id: 33,
        label: 'WhatsApp:',
        value: '(21) 96808-7595',
        link: `${LINKS.wpp}/?text=Digite%20sua%20dúvida`
      },
    ]
  },
  {
    id: 4,
    title: 'Horário de atendimento',
    icon: 'calendar-alt',
    texts: [
      {
        id: 41,
        value: 'Seg. à Sex das 09h às 18h',
      },
      {
        id: 42,
        value: 'Sáb. das 09h às 13h',
      },
    ]
  }
]