import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openPage } from '../../../../utils/helpers/window'
import { LINKS } from '../../../../utils/mockData/links'

export const WppButton = () => {
  return (
    <Button
      onClick={() => openPage(`${LINKS.wpp}/?text=Digite%20sua%20dúvida`)}
      className="rounded-circle btn-success shadow"
      style={{
        position: 'fixed',
        zIndex: 1,
        bottom: '20px',
        right: '20px',
        width: '4rem',
        height: '4rem',
      }}
    >
      <FontAwesomeIcon
        className="fs-1"
        icon={['fab', 'whatsapp']}
      />
    </Button>
  )
}
