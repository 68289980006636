import { useNavigate, useParams } from "react-router-dom"
import Button from "react-bootstrap/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SonixNavbar } from "../../../../components/SonixNavbar"
import { SonixFooter } from "../../../../components/SonixFooter"
import { BLOG_CONTENT } from "../../../../utils/mockData/blogContent"
import { CONTENT } from "../../../../utils/mockData/homeContent"
import { ROUTE_PATHS } from "../../../../utils/constants/Paths"

export const BlogContent = () => {
  const navigate = useNavigate()
  const goBack = () => {
    navigate(ROUTE_PATHS.blog)
  }

  const { id } = useParams()
  const currentContent = BLOG_CONTENT.find(content => content.path.includes(id)) || false;

  return (
    <>
      <SonixNavbar isBlog={true} />
      {
        currentContent ? (
          <section className="bg-body-tertiary min-vh-100">
            <img src={currentContent.image.src} alt={currentContent.image.alt} className="img-fluid" />
            <div className="p-3">
              <h1> {currentContent.title} </h1>
              <small className="text-body-secondary">
                <FontAwesomeIcon icon={['fas', 'clock-four']} className="mx-2" />
                {currentContent.readingTime}
              </small>
              <hr />
              {
                currentContent.sections.map(({ id, text, subtitle, list }) => (
                  <div key={id} className="text-secondary">
                    {
                      subtitle && <h2>{subtitle}</h2>
                    }
                    {
                      text && <p>{text}</p>
                    }
                    {
                      list && (
                        <ul>
                          {
                            list.map(({ id, text }) => (
                              <li key={id}>{text}</li>
                            ))
                          }
                        </ul>
                      )
                    }
                  </div>
                ))
              }
            </div>
            <div className="mb-3 mx-3">
              <Button type="button" variant="outline-secondary" className="w-100 btn-lg" onClick={goBack}>Visualizar mais conteúdos</Button>
            </div>
          </section>
        )
          :
          (
            <section className="vh-100">
              <div className="py-5 d-flex text-center flex-column bg-dark mb-5">
                <p className="lead text-uppercase text-white"> {CONTENT.blog.breadcrumb} </p>
                <h2 className="mb-3 text-white">{CONTENT.blog.page_title}</h2>
              </div>
              <div className="m-3">
                <Button type="button" variant="outline-secondary" className="w-100 btn-lg mb-3" onClick={goBack}>Visualizar todos os conteúdos</Button>
                <p className="lead text-center">
                  Conteúdo não encontrado
                </p>
              </div>
            </section>
          )
      }
      <SonixFooter />
    </>
  )
}