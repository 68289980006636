export const ProductsWave = ({ needToRotate, hasBg }) => {
  return (
    <div style={{
      backgroundColor: hasBg ? `#f8f9fa` : 'initial',
      marginBottom: hasBg ? `-2px` : '0',
    }}>
      <svg
        style={
          {
            transform: `rotate(${needToRotate ? '180deg' : 0})`,
            marginTop: needToRotate ? '-2px' : '0',
          }
        }
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#6c757d" fillOpacity="1" d="M0,128L48,112C96,96,192,64,288,48C384,32,480,32,576,37.3C672,43,768,53,864,85.3C960,117,1056,171,1152,170.7C1248,171,1344,117,1392,90.7L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
      </svg>
    </div>
  )
}