export const DEFAULT_ROUTE_PATH = '/'
export const ASSETS_PATH = `${process.env.PUBLIC_URL}/assets/`

export const ROUTE_PATHS = {
  admin: `${DEFAULT_ROUTE_PATH}admin`,
  login: `${DEFAULT_ROUTE_PATH}login`,
  blog: `${DEFAULT_ROUTE_PATH}blog`,
  home: `${DEFAULT_ROUTE_PATH}`
}

export const NAVBAR_PATHS = {
  about: "about",
  products: "products",
  services: "services",
  partner: "be-partner",
  contact: "contact"
}
