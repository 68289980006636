import { ASSETS_PATH } from "../constants/Paths";

export const PRODUCTS = [
  {
    id: 1,
    title: 'Aparelho auditivo BTE (Retroauricular)',
    src: `${ASSETS_PATH}aparelho_bte.png`,
    text: 'Aparelho Auditivo BTE. O modelo mais conhecido de aparelhos aparelhos auditivos. Disponível na versão padrão, mini ou potente. Também possuem conectividade e possuem a versão tubo fino e recarregável.'
  },
  {
    id: 4,
    title: 'Aparelho auditivo RIC',
    src: `${ASSETS_PATH}ric.png`,
    text: 'Aparelhos com receptor no canal são discretos e versáteis. Podem ser adaptados em perdas de grau leve a profundo. Possuem modelos recarregáveis e à bateria.'
  },
  {
    id: 2,
    title: 'Aparelho intracanal',
    src: `${ASSETS_PATH}aparelho_intracanal.jpg`,
    text: 'As principais vantagens de um aparelho intracanal é a discrição e conforto. Fácil adptação, são modelos customizados e atendem perdas auditivas leves e severas. Além disso possui conectividade com seu celular e outros acessórios.'
  },
  {
    id: 3,
    title: 'Aparelho recarregável',
    src: `${ASSETS_PATH}aparelho_recarregavel.jpg`,
    text: 'A última geração de aparelhos auditivos digitais recarregáveis permite conectar seus aparelhos auditivos digitais a aplicativos, acessórios e outros dispositivos inteligentes.'
  },
  {
    id: 5,
    title: 'Baterias',
    src: `${ASSETS_PATH}baterias_recarregaveis.jpeg`,
    text: 'Todos os tamanhos de baterias para seu aparelho auditivo você encontra aqui.'
  },
  {
    id: 6,
    title: 'Testador de bateria',
    src: `${ASSETS_PATH}testador_de_bateria.jpeg`,
    text: 'O testador de bateria funciona para poder ver a carga de sua bateria e evitar o descarte desnecessário. Funciona para todos os tamanhos.'
  },
  {
    id: 7,
    title: 'Desumidificador e sílica',
    src: `${ASSETS_PATH}desumidificador.jpeg`,
    text: 'Desumidificadores e sílica no modelo tradicional ou elétrico. Indicado para tirar a umidade do aparelho e para pacientes com sudorese excessiva.'
  },
  {
    id: 8,
    title: 'Receptores',
    src: `${ASSETS_PATH}acessorio_conectividade_1.jpg`,
    text: 'Receptores de vários tamanhos e potências.'
  },
  {
    id: 9,
    title: 'Acessório conectividade',
    src: `${ASSETS_PATH}acessorio_conectividade_2.jpg`,
    text: 'Acessório conectividade'
  },
  {
    id: 10,
    title: 'Olivas/Sondas',
    src: `${ASSETS_PATH}olivas_sondas.png`,
    text: 'Olivas e sondas nos modelos aberta, fechada, dupla ou power. E tulipa em diversos tamanhos e adaptados a cada conduto auditivo de maneira confortável.'
  },
  {
    id: 11,
    title: 'Protetores de natação',
    src: `${ASSETS_PATH}protetores_natacao.jpeg`,
    text: 'O protetor de natação pode ser estilizado em cores de sua preferência, protege seu ouvido para atividades aquáticas ou na hora do banho.'
  },
  {
    id: 12,
    title: 'Tubo fino',
    src: `${ASSETS_PATH}tubo_fino.jpeg`,
    text: 'Tubo fino para aparelhos de adaptação aberta. Tubos de diversos tamanhos.'
  },
  {
    id: 13,
    title: 'Moldes para aparelho',
    src: `${ASSETS_PATH}moldes_aparelho.jpeg`,
    text: 'Moldes para aparelhos auditivos no modelo BTE ou retroauricular, diversos modelos. Material pode ser de acrílico ou silicone.'
  },
]