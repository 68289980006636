import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom"

import { Home } from './pages/Home'
import { ROUTE_PATHS } from './utils/constants/Paths'
import { Blog } from './pages/Blog'
import { BlogContent } from './pages/Blog/components/BlogContent'

export const AppRouter = () => {
  return (
    <Routes>
      <Route path={ROUTE_PATHS.home} element={<Home />} />
      <Route path="/blog/:id" element={<BlogContent />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="*" element={<Navigate to={ROUTE_PATHS.home} />} />
    </Routes>
  )
}
