import { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from 'react-bootstrap/Card'
import { LINKS } from '../../utils/mockData/links'
import { openPage } from '../../utils/helpers/window'

export const SonixCard = ({ cardTitle, cardText, cardImageName, biggerHeight }) => {
  const CARD_WIDTH = '300px'
  const CARD_MIN_HEIGHT = '365px'
  const CARD_HEIGHT = '400px'

  const cardBodyRef = useRef()
  const [hasScrollbar, setHasScrollbar] = useState(false)

  const stringsToCheck = ['Acessório'];
  const biggerImages = stringsToCheck.some(str => cardTitle.indexOf(str) > -1);
  const hasImageAuto = !biggerHeight && !biggerImages

  useEffect(() => {
    if (!cardBodyRef?.current) return
    const cardBodyScrollHeight = cardBodyRef.current.scrollHeight
    const cardBodyHeight = cardBodyRef.current.clientHeight
    if (cardBodyScrollHeight > cardBodyHeight) setHasScrollbar(true)
  }, [])

  return (
    <Card
      className="p-3 shadow"
      style={{ minWidth: CARD_WIDTH, width: CARD_WIDTH, minHeight: CARD_MIN_HEIGHT, height: CARD_HEIGHT, maxHeight: CARD_HEIGHT, cursor: 'pointer' }}
      onClick={() => openPage(`${LINKS.wpp}/?text=Gostaria%20de%20saber%20sobre%20${cardTitle}`)}
    >
      <Card.Img
        variant="top"
        src={`${cardImageName}`}
        className={hasImageAuto ? 'm-auto w-auto' : 'img-fluid'}
        width="168"
        height="168"
      />
      <Card.Title className="mt-2">{cardTitle}</Card.Title>
      {
        cardText && (
          <Card.Body className="overflow-y-auto" ref={cardBodyRef}>
            <Card.Text> {cardText} </Card.Text>
          </Card.Body>
        )
      }
      {
        hasScrollbar && (
          <span className="arrow-sinalization">
            <FontAwesomeIcon className="text-primary" icon={['fas', 'arrow-down']} />
          </span>
        )
      }
    </Card>
  )
}
