import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button'
import { LINKS } from '../../utils/mockData/links'
import { openPage } from '../../utils/helpers/window'

export const SonixFooter = () => {
  return (
    <footer className="d-flex flex-column align-items-center bg-dark" style={{ marginTop: '-1px' }}>
      <div className="d-flex gap-4 mx-5 flex-wrap align-items-center justify-content-center">
        <Button
          variant="link"
          className="text-secondary fs-3"
          onClick={() => openPage(LINKS.fb)}
        >
          <FontAwesomeIcon icon={['fab', 'facebook-f']} />
        </Button>

        <Button
          variant="link"
          className="text-secondary fs-3"
          onClick={() => openPage(LINKS.instagram)}
        >
          <FontAwesomeIcon icon={['fab', 'instagram']} />
        </Button>

        <Button
          variant="link"
          className="text-secondary fs-3"
          onClick={() => openPage(LINKS.yt)}
        >
          <FontAwesomeIcon icon={['fab', 'youtube']} />
        </Button>

        <Button
          variant="link"
          className="text-secondary fs-3"
          onClick={() => openPage(LINKS.linkedin)}
        >
          <FontAwesomeIcon icon={['fab', 'linkedin']} />
        </Button>
      </div>

      <p className="text-secondary mt-2 mx-5">
        Copyright © 2023 - Sonix Aparelhos Auditivos - Todos os Direitos Reservados.
      </p>
    </footer>
  )
}