import Button from 'react-bootstrap/Button'
import { NAVBAR_PATHS } from '../../../../utils/constants/Paths'
import { CONTENT } from '../../../../utils/mockData/homeContent'
import { openPage } from '../../../../utils/helpers/window'
import { LINKS } from '../../../../utils/mockData/links'

export const BePartnerSection = () => {
  return (
    <section
      id={NAVBAR_PATHS.partner}
      className="p-5 d-flex flex-column"
    >
      <p className="lead text-uppercase text-center"> {CONTENT.bePartner.breadcrumb} </p>

      <h2 className="lh-1 m-0 text-center">{CONTENT.bePartner.page_title}</h2>

      <div className="mt-5 d-flex align-items-center justify-content-center flex-column">
        <p className="lead">
          {CONTENT.bePartner.title}
        </p>
        <Button type="button" size="lg" onClick={() => openPage(LINKS.googleForms)}>{CONTENT.bePartner.button_label}</Button>
      </div>
    </section>
  )
}
