import { ASSETS_PATH } from "../constants/Paths";

export const BLOG_CONTENT = [
  {
    id: 0,
    title: 'Projeto Beethoven',
    brief: 'O projeto tem o intuito de trazer de volta a qualidade de vida para aqueles que não possuem condições de adquirir um aparelho, através da doação da prótese.',
    readingTime: '5 min',
    image: {
      src: `${ASSETS_PATH}projeto_beethoven.jpeg`,
      alt: 'Projeto Beethoven'
    },
    path: '/blog/projeto-beethoven',
    sections: [{
        id: 10,
        text: 'O projeto tem o intuito de trazer de volta a qualidade de vida para aqueles que não possuem condições de adquirir um aparelho, através da doação da prótese. Este projeto tem o objetivo de aproximar corações, tirar do isolamento auditivo e intermediar esse processo, através da doação de aparelhos auditivos de pacientes que desejam doar seus antigos.'
      },
      {
        id: 11,
        text: 'Ludwig van Beethoven É CONSIDERADO um dos compositores mais respeitados e mais influentes de todos os tempos.. Nasceu na Alemanha em 1770 e faleceu na Áustria em 1827.'
      },
      {
        id: 12,
        text: 'Foi em Viena que lhe surgiram os primeiros sintomas da sua perda de audição. e aos 26 anos de idade, foi diagnosticado com “congestão dos centros auditivos internos” , o que lhe transtornou bastante o espírito, levando-o a isolar-se e a grandes depressões. No trecho de uma carta, ele escreve:'
      },
      {
        id: 13,
        text: '“Devo viver como um exilado. Se me acerco de um grupo, sinto-me preso de uma pungente angústia, pelo receio que descubram meu triste estado. E assim vivi este meio ano em que passei no campo. Mas que humilhação quando ao meu lado alguém percebia o som longínquo de uma flauta e eu nada ouvia! Ou escutava o canto de um pastor e eu nada escutava! Esses incidentes levaram-me quase ao desespero e pouco faltou para que, por minhas próprias mãos, eu pusesse fim à minha existência. Só a arte me amparou!”'
      },
      {
        id: 14,
        text: 'Embora tenha feito muitas tentativas para se tratar, a doença continuou a progredir e, aos 46 anos de idade, estava praticamente surdo. Porém, ao contrário do que muitos pensam, Ludwig jamais perdeu a audição por completo, muito embora nos seus últimos anos de vida a tivesse perdido, condições que não o impediram de acompanhar uma apresentação musical ou de perceber nuances sonoras.'
      }
    ]
  },
  {
    id: 1,
    title: 'Perda Auditiva',
    brief: `Minha TV está muita alta, eu escuto, mas não entendo as pessoas, estou ficando surdo? Como posso saber se tenho perda auditiva?`,
    readingTime: '15 min',
    image: {
      src: `${ASSETS_PATH}perda_auditiva.jpeg`,
      alt: 'Perda auditiva'
    },
    path: '/blog/perda-auditiva',
    sections: [
      {
        id: 101,
        text: 'Minha TV está muita alta, eu escuto, mas não entendo as pessoas, estou ficando surdo? Como posso saber se tenho perda auditiva?'
      },
      {
        id: 102,
        text: 'A maioria dos casos de perda auditiva desenvolve-se gradualmente, e os sintomas por incrível que pareça são difíceis de serem reconhecidos. Muitas vezes um parente sinaliza, pois percebe a dificuldade que a pessoas tem em seguir um diálogo sem perguntar, o que? Pode repetir? Não entendi. A pessoa também pode perceber a perda quando escuta muito melhor no telefone de um ouvido do que do outro, ou quando só consegue entender a TV se aumentar o volume e em conversas com muitas pessoas ou em ambientes com ruído, perde algumas palavras ou fica sem entender.'
      },
      {
        id: 103,
        text: 'Se você tem experimentado alguns sinais relacionados à dificuldade de ouvir, o primeiro passo é se consultar com um médico Otorrinolaringologista e realizar um exame de audiometria. Como existem diversas causas para a perda e diversos tratamentos, ele irá determinar se você é indicado para o uso de um aparelho auditivo ou não.'
      },
      {
        id: 104,
        text: 'Caso seu exame tenha dado alguma alteração, converse com seu médico. Caso precise usar um aparelho auditivo, entre em contato conosco e agende uma consulta, nossas fonoaudiólogas são preparadas para tirar dúvidas e achar uma solução junto com você.'
      },
      {
        id: 105,
        text: 'As consultas no centro auditivo não são cobradas. Aqui você irá realizar um orçamento de aparelho e selecionar um aparelho que melhor se adapte na sua perda e no seu perfil. Após a compra do aparelho as sessões de ajuste e manutenção do aparelho auditivo também não é cobrada.'
      },
      {
        id: 106,
        subtitle: 'Tipos de perda auditiva'
      },
      {
        id: 107,
        text: 'Perda auditiva pode ser condutiva, mista e sensorioneural. A perda auditiva condutiva é quando a condução do som para o ouvido interno está prejudicada, podendo estar bloqueada ou reduzida. A perda auditiva sensorioneural é causada devido a danos ocorridos no ouvido interno, nas células ciliadas. Se houver problemas ao conduzir o som para o ouvido interno, e ao mesmo tempo nas células ciliadas do ouvido interno, então a perda auditiva é chamada de mista. Na verdade, é uma combinação de perda auditiva condutiva e perda auditiva sensorioneural.'
      },
      {
        id: 108,
        subtitle: 'Graus de perda auditiva'
      },
      {
        id: 109,
        text: 'São os níveis de perda, que pode ser leve, moderada, severa ou profunda. Os graus são dados de acordo com os níveis de decibéis que a pessoa escuta em cada frequência.'
      },
      {
        id: 110,
        subtitle: 'Como é feito o exame de audição'
      },
      {
        id: 111,
        text: 'Quem faz o exame de audição é o profissional fonoaudiólogo, com equipamentos adequados e que devem estar devidamente calibrados e aferidos por um órgão competente para que o exame seja válido. Muito cuidado pois, existem muitas pessoas que não são habilitadas para realizar esse teste. Vá sempre por indicação médica ou referência. Muitos médicos otorrinos possuem um fonoaudiólogo trabalhando com ele, ou encaminham para que você realize esse exame. O exame de audiometria mede a quantidade que cada pessoa escuta em uma determinada frequência e o quanto ela consegue discriminar (em porcentagem), ou seja, entender os sons da fala.'
      },
      {
        id: 112,
        text: 'Quando alguém chega dizendo, eu escuto 60% ou tenho 30 % de audição, na verdade isso é uma forma errada de medir, já que cada frequência é analisada separadamente em níveis de intensidade, ou decibéis.'
      },
      {
        id: 113,
        subtitle: 'O que é deficiência auditiva?'
      },
      {
        id: 114,
        text: 'Deficiência auditiva (perda auditiva) é quando a habilidade auditiva da pessoa está reduzida e faz com que a pessoa tenha dificuldade de ouvir diálogos e outros sons. A perda auditiva leva a outros fatores como isolamento social, dissociação, depressão e até demência. Pode causar também desequilíbrio, dificuldades na localização sonora e aumento em risco de queda.'
      },
      {
        id: 115,
        subtitle: 'Causas de deficiência auditiva'
      },
      {
        id: 116,
        text: 'As causas mais comuns da perda auditiva são exposição a ruídos, uso de medicamentos e o próprio envelhecimento.'
      },
      {
        id: 117,
        text: 'Deficiência auditiva pode ser causada por vários fatores, mas envelhecimento e ruído são as duas causas mais comuns. Perder a audição com a idade é uma consequência natural e mais da metade das pessoas, ao atingir 80 anos de idade, sofre com a perda de audição, conhecida como Presbiacusia.'
      },
      {
        id: 118,
        text: 'A Deficiência auditiva também pode ser consequência do uso de headphones, som alto, shows, discotecas, ruídos do ambiente de trabalho constantes, como por exemplo ruídos de motores e máquinas. Já é comprovado que o uso elevado de aparelho mp3 aumenta os efeitos de deficiência auditiva.'
      },
      {
        id: 119,
        subtitle: 'A perda auditiva pode ser causada por:'
      },
      {
        id: 120,
        list: [
          {
            id: 1201,
            text: 'Certos medicamentos chamados ototóxicos;'
          },
          {
            id: 1202,
            text: 'Algumas doenças e infecções;'
          },
          {
            id: 1203,
            text: 'Algumas síndromes;'
          },
          {
            id: 1204,
            text: 'Lesões no ouvido;'
          },
          {
            id: 1205,
            text: 'Lesões na cabeça'
          },
          {
            id: 1206,
            text: 'Malformações congênitas do ouvido ou entupimento no ouvido;'
          },
          {
            id: 1207,
            text: 'Fatores genéticos;'
          },
          {
            id: 1208,
            text: 'Tumores na cabeça;'
          },
          {
            id: 1209,
            text: 'Alcoolismo e tabagismo;'
          },
          {
            id: 1210,
            text: 'Químicos;'
          },
          {
            id: 1211,
            text: 'Colesterol.'
          },
        ]
      },
      {
        id: 121,
        subtitle: 'Tratamento da perda auditiva'
      },
      {
        id: 122,
        text: 'Algumas perdas auditivas são tratadas com diferentes tipos de implantes, e cirurgias podem curar alguns tipos de perda auditiva condutiva.'
      },
      {
        id: 123,
        text: 'Perda auditiva sensorioneural pode ser tratada, normalmente, com o uso de aparelho auditivo. Já a perda auditiva condutiva pode, com frequência, ser tratada com procedimento cirúrgico, ou através de remoção do entupimento auditivo. Em alguns casos, se faz necessário o uso de aparelho auditivo ou implantes. A perda auditiva súbita pode ser tratada imediatamente e o tratamento, com frequência, envolve o uso de esteroides. Se você tem experimentado perda auditiva súbita, você deve procurar seu médico logo que possível.'
      },
      {
        id: 124,
        text: 'Na maioria dos casos a deficiência auditiva não pode ser curada, mas normalmente pode ser corrigida com o uso de aparelhos auditivos.'
      },
      {
        id: 125,
        subtitle: 'O que é perda auditiva Súbita (SSNHL)' 
      },
      {
        id: 126,
        text: 'Perda auditiva pode ocorrer subitamente, de um dia para outro, o que é conhecido como perda auditiva súbita. Foi identificada no século passado, mas ainda permanece um enigma sua causa, somente sendo possível ser identificada em 10 a 15 % dos casos. Pode ser diagnosticada com perda de intensidade sonora acima de 30 decibéis em três frequências consecutivas por mais de três dias seguidos. Pode ser percebida ao acordar e permanecer sem ouvir durante longos períodos de horas ou dias.'
      },
      {
        id: 127,
        text: 'Em 70 % dos casos os pacientes possuem zumbidos associados a 50 % possuem vertigem. A incidência de perda súbita aumenta com a idade, ou seja, quanto mais idade, maiores as chances de ter perda súbita.'
      },
      {
        id: 128,
        text: 'De 30-70% dos casos melhoram espontaneamente dentro de duas a quatro semanas, no entanto se a perda auditiva for severa ou se existir vertigem associada é mais difícil ocorrer melhora total da audição. Quanto mais jovem maiores são as chances de remissão total do quadro.'
      },
      {
        id: 129,
        bibliography: 'Fonte: Hearing Review, December 2003, Sudden Sensorineural Hearing Loss special issue.'
      },
      {
        id: 130,
        subtitle: 'Prevenção de deficiência auditiva'
      },
      {
        id: 131,
        text: 'Existem algumas formas de prevenir a perda auditiva. Como evitar a exposição a ruídos intensos, limitar o uso de headphones e se o nível de ruído é muito alto, recomenda-se usar tampões de ouvido. Exames auditivos periódicos é importante para acompanhamento clínico, ainda mais se há casos de perda de audição na família.'
      }
    ]
  },
  {
    id: 2,
    title: 'O que é o Zumbido?',
    brief: 'Segundo dados da Organização Mundial da Saúde (OMS), o zumbido afeta 278 milhões de pessoas e no Brasil, são 28 milhões que convivem com o sintoma.',
    readingTime: '10 min',
    image: {
      src: `${ASSETS_PATH}zumbido.jpeg`,
      alt: 'Zumbido'
    },
    path: '/blog/zumbido',
    sections: [
      {
        id: 20,
        text: 'Segundo dados da Organização Mundial da Saúde (OMS), o zumbido afeta 278 milhões de pessoas e no Brasil, são 28 milhões que convivem com o sintoma. O zumbido pode levar à depressão e ansiedade, fazendo com que a pessoa se torne inclusive disfuncional. Porém há tratamentos que envolvem até mesmo o uso de aparelhos auditivos que possuem geradores sonoros, que ao “competir” com o chiado característico do zumbido, conseguem tratá-lo.'
      },
      {
        id: 21,
        subtitle: 'O que é o Zumbido?'
      },
      {
        id: 22,
        text: 'Zumbido, tinitus ou acúfenos, é a percepção do som ou ruído em um ou dois ouvidos quando não há som advindo do ambiente, ou seja, é um barulho que a pessoa escuta dentro do ouvido ou da cabeça.'
      },
      {
        id: 23,
        text: 'Embora não exista uma cura comprovada para o zumbido, o entendimento dos sintomas, causas e doenças relacionadas pode ajudar a aliviar, controlar ou bloquear a sensação de zumbido nos ouvidos.'
      },
      {
        id: 24,
        subtitle: 'Sintomas de zumbido'
      },
      {
        id: 25,
        text: 'O zumbido pode ser grave, agudo, como um chiado, uma cigarra, apito ou como muitos relatam como o som de uma “panela de pressão”, ou como se a TV estivesse ligada.'
      },
      {
        id: 26,
        text: 'Para alguns, o zumbido parece ficar mais alto à noite, pouco antes de dormir e pode ser constante ou intermitente.'
      },
      {
        id: 27,
        text: 'Em casos graves, o zumbido nos ouvidos é alto o suficiente para interferir no trabalho ou atividade diária, podendo ocasionar depressão e ansiedade.'
      },
      {
        id: 28,
        subtitle: 'Causas do zumbido'
      },
      {
        id: 29,
        text: 'É importante investigar o que pode vir causa a sensação de zumbido. Enquanto muitas pesquisas ainda são inconclusivas a respeito, existem algumas causas comuns que, em geral, são conhecidas por agravar a condição:'
      },
      {
        id: 30,
        list: [
          {
            id: 300,
            text: 'Fator Idade: Por volta dos 60 anos, a sensibilidade auditiva pode piorar. A perda auditiva associada ao envelhecimento natural é denominada presbiacusia e pode ser acompanhada de zumbido'
          },
          {
            id: 301,
            text: 'Exposição a ruídos altos: Estar exposto a ruídos altos ocupacionais em uma base regular de equipamentos pesados, motosserras ou armas de fogo é uma causa comum de zumbido. No entanto, mesmo que você não trabalhe em um ambiente barulhento, ainda pode sofrer os efeitos da exposição ao ruído ouvindo música alta através de fones de ouvido, assistindo com frequência a apresentações de música ao vivo e fazendo passatempos barulhentos.'
          },
          {
            id: 302,
            text: 'Hábitos insalubres: os pesquisadores não sabem ao certo o porquê, mas beber álcool, fumar, comer certos alimentos e consumir bebidas cafeinadas pode ter um papel no zumbido.'
          },
          {
            id: 303,
            text: 'Problemas comuns: Ter anemia, alergias, hipertensão arterial, doenças cardiovasculares, problemas circulatórios, diabetes e hipoatividade da tireóide são condições médicas que podem levar ao zumbido.'
          }
        ],

      },
      {
        id: 31,
        subtitle: 'Tratamento'
      },
      {
        id: 32,
        text: 'O primeiro passo é se consultar com um médico Otorrinolaringologista, ele irá solicitar exames complementares para entender de onde vem o zumbido. Alguns medicamentos auxiliam na diminuição da ansiedade e do zumbido, enquanto o uso de outros fármacos também pode desencadear o zumbido, por isso é de extrema importância investigar e avisar ao seu médico quais remédios está fazendo uso.'
      },
      {
        id: 33,
        text: 'Pessoas com zumbido realmente podem vir a sofrer com ele, outras se acostumam com ele e o zumbido começa a fazer parte comum da vida cotidiana. Na realidade elas “aprendem” a conviver, lidar e enfrentá-lo. Algumas pessoas também recorrem a terapias alternativas para encontrar alívio.'
      },
      {
        id: 34,
        text: 'O uso de aparelhos auditivos pode ser outra solução para quem tem perda auditiva e zumbido, pois o aparelho auditivo devidamente ajustado funciona como um mascarador do zumbido.'
      },
      {
        id: 35,
        text: 'Existem aparelhos com gerador de som que funcionam como terapia coadjuvante, eles produzem ruídos terapêuticos calmantes para desviar o foco do zumbido. Outros dispositivos produzem ruído constante e suave para mascarar o zumbido. O uso desses dispositivos é indicado por um fonoaudiólogo capacitado e de acordo com o tipo de zumbido e grau de incomodo, melhorando assim o zumbido de forma considerável.'
      },
      {
        id: 36,
        text: 'Outros tipos de terapia como o TRT (Terapia de habituação do zumbido) é uma forma de terapia projetada para ajudar pessoas que sofrem de zumbido, é um tipo de terapia cognitiva comportamental que auxilia na terapia do zumbido. Além de sessões de aconselhamento, um gerador de ruído branco é usado para criar sons ambientais para anular o zumbido. Alguns fonoaudiólogos possuem capacitação para realizar tal terapia.'
      },
      {
        id: 37,
        text: 'Muitas pessoas acham que melhorar sua saúde geral fornece algum conforto de sintomas de zumbido. Isso significa controlar sua pressão arterial, reduzindo o estresse e diminuindo o consumo de cafeína. Outras estratégias de alívio do zumbido incluem exercícios de relaxamento, meditação e visualização.'
      },
      {
        id: 38,
        text: 'Embora não exista uma cura comprovada para o zumbido, existem tratamentos que facilitam a ignorar.'
      },
      {
        id: 39,
        subtitle: 'Doença de Meniere'
      },
      {
        id: 40,
        text: 'A doença de Meniere não está diretamente ligada ao zumbido, mas as pessoas com Meniere frequentemente a experimentam, pelo menos temporariamente. A doença de Meniere é uma doença da orelha interna. Esta doença pode causar pressão ou dor no ouvido, casos graves de tontura ou vertigem e um zumbido. Embora a doença não seja totalmente entendida, várias opções de alívio para o zumbido também podem ajudar nessa doença. Os pacientes são frequentemente aconselhados a reduzir o estresse e diminuir o consumo de cafeína e sódio.'
      },
      {
        id: 41,
        subtitle: 'Obtendo ajuda para o zumbido'
      },
      {
        id: 42,
        text: 'Se você tem zumbido, você pode estar se sentindo frustrado e desamparado, mas há esperança! O primeiro passo é consultar um fonoaudiólogo em um dos nossos centros auditivos para ajudá-lo a recuperar sua qualidade de vida.'
      },
      {
        id: 43,
        text: 'Além disso, todos os nossos aparelhos possuem a mais avançada tecnologia multiflex para terapia de zumbido.'
      }
    ]
  }
]
